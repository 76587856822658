<template>
  <v-dialog v-model="show" max-width="600px">
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-alert
      border="top"
      colored-border
      color="#512E96"
      elevation="2"
      class="mb-0 px-10 pt-14 pb-8"
      v-else
    >
      <div class="sectionTitle mb-7">Share this Fundraiser</div>
      <div>
        <v-sheet class="shareTextBox py-5 px-7" color="#F3F3F3">
          <span v-html="shareTemplate"></span>
        </v-sheet>
      </div>
      <div class="my-8">
        <div class="d-flex" style="justify-content: space-evenly">
          <div>
            <a
              :href="facebookHref"
              @click="postShareDetails('facebook')"
              target="_blank"
              title="Share on Facebook"
            >
              <v-img
                style="cursor: pointer; margin-left: 28%"
                width="150px"
                height="60px"
                src="@/assets/thingsToDo/fbimage.jpg"
              ></v-img>
            </a>
            <span style="font-size: 15px"
              ><span style="color: red">*</span>Facebook can only be shared
              using a desktop or mobile browser</span
            >
          </div>
        </div>
      </div>

      <div>
        <v-btn
          v-if="false"
          @click="sendEmail()"
          class="py-6 mr-5"
          :width="$vuetify.breakpoint.smAndUp ? '47%' : '45%'"
          color="#252121"
          dark
        >
          <v-icon left> mdi-email </v-icon>
          <span class="btn-text"> Email </span>
        </v-btn>
        <v-btn
          v-clipboard:copy="url"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          class="py-6"
          color="#2C1963"
          outlined
          :width="$vuetify.breakpoint.smAndUp ? '100%' : '100%'"
        >
          <span class="btn-text"> Copy URL Link </span>
        </v-btn>
      </div>
      <div
        class="mt-5"
        style="text-align: -moz-center; text-align: -webkit-center"
      >
        <div class="bottomMsgText">
          Be sure to help make this fundraiser a success by sharing it to family
          and friends.
        </div>
      </div>
    </v-alert>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  API_PUBLIC_SHARE_FUNDRAISER_TEMPLATE,
  API_PUBLIC_SHARE_POST_DETAILS,
} from "@/constants/APIUrls";
import Helper from "@/helper";

export default {
  name: "ShareFundraiserModal",
  components: {},
  data() {
    return {
      loading: true,
      url: "",
      title: "WizFit Challenge",
      shareTemplate: "",
      emailSubject: "",
      emailBody: "",
      templateId: -1,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      showGetter: "publicDonationPage/getShareFundraiserModalShow",
      getCampaign: "publicDonationPage/getCampaign",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    getCampaignDetail() {
      return this.getCampaign;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleModal({ show: value });
      },
    },
    imageWidth() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return "40px";
      } else {
        return "30px";
      }
    },
    facebookHref() {
      // <a href="https://facebook.com/page" className="large-screen">Clicky</a>
      // <a href="fb://page/mypage" className="small-screen">Clicky</a>
      // let href = `${this.$vuetify.breakpoint.smAndDown ? 'fb://' : 'https://facebook.com/'}sharer/sharer.php?u=${this.url.replace(/&/g, "%26")}`
      let href = `${
        this.$vuetify.breakpoint.smAndDown
          ? "https://facebook.com/"
          : "https://facebook.com/"
      }sharer/sharer.php?u=${this.url.replace(/&/g, "%26")}`;
      return href;
    },
    linkdInHref() {
      //https://www.linkedin.com/shareArticle?mini=true&url=https://www.perpetual.com.au/insights/equity-market-rally-set-to-boost-income
      let href = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        this.url
      )}`;
      return href;
    },
    twitterHref() {
      let href = `https://twitter.com/share?text=${
        this.title
      }&url=${this.url.replace(/&/g, "%26")}`;
      return href;
    },
    whatsappShareText() {
      const baseUrl = this.$vuetify.breakpoint.smAndDown
        ? "whatsapp://"
        : "https://web.whatsapp.com/";
      return baseUrl + "send?text=" + this.url.replace(/&/g, "%26");
    },
  },
  watch: {
    showGetter(flag) {
      if (flag) {
        this.getDetails();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleModal: "publicDonationPage/toggleShareFundraiserModal",
      showToast: "snackBar/showToast",
    }),
    getDetails() {
      const _this = this;
      const successHandler = (res) => {
        console.log("Success: ", res.data);
        this.shareTemplate = res.data.email_template_detail.email_body_preview;
        _this.url = res.data.email_template_detail.share_url;
        _this.emailBody = res.data.email_template_detail.email_body_str;
        _this.emailSubject =
          res.data.email_template_detail.email_subject_preview;
        _this.templateId = res.data.email_template_detail.id;
        _this.loading = false;
      };
      const failureHandler = (res) => {
        console.log("Failure: ", res);
        _this.loading = false;
      };
      let formData = {};
      formData["email_template_type"] = "share";
      if (this.$route.query.student) {
        console.log("student", _this.getCampaignDetail.campaign_detail.id);
        formData["shared_by"] = "student";
        formData["user_id"] = this.$route.query.student;
        formData["campaign_id"] = _this.getCampaignDetail.campaign_detail.id;
        // Formatting URL
        let domain = new URL(window.location.href);
        let redirectURL =
          domain.origin + `?student=${domain.searchParams.get("student")}`;
        formData["redirect_url"] = redirectURL;
      } else if (this.$route.query.teacher) {
        formData["shared_by"] = "teacher";
        formData["user_id"] = this.$route.query.teacher;
        formData["campaign_id"] = _this.selectedCampaign.campaignData.id;
        // Redirect Url Formatting
        const PANEL = "teacher";
        const id = this.selectedCampaign.organizationData.id;
        const sub_domain = this.selectedCampaign.campaignData.sub_domain;
        const queryParam = PANEL + "=" + id;
        formData["redirect_url"] = Helper.openPublicDonationPageHref(
          sub_domain,
          queryParam
        );
      } else {
        formData["shared_by"] = "teacher";
        formData["user_id"] = _this.selectedCampaign.organizationData.id;
        formData["campaign_id"] = _this.selectedCampaign.campaignData.id;
        // Redirect Url Formatting
        const PANEL = "teacher";
        const id = this.selectedCampaign.organizationData.id;
        const sub_domain = this.selectedCampaign.campaignData.sub_domain;
        const queryParam = PANEL + "=" + id;
        formData["redirect_url"] = Helper.openPublicDonationPageHref(
          sub_domain,
          queryParam
        );
        console.log(
          "this.selectedCampaignData.campaignData.id",
          this.selectedCampaign.organizationData.id
        );
      }
      Axios.request_GET(
        API_PUBLIC_SHARE_FUNDRAISER_TEMPLATE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    shareClickHandler(type) {
      console.log("Type: ", type);
    },
    sendEmail() {
      this.postShareDetails("email");
      let subject = this.emailSubject;
      let body = this.emailBody;
      window.open(
        "mailto:test@example.com?subject=" + subject + "&body=" + body
      );
    },
    onCopy: function () {
      this.showToast({ message: "URL Copied", color: "s" });
    },
    onError: function () {
      this.showToast({ message: "URL NOT Copied", color: "e" });
    },
    postShareDetails(platform) {
      // const _this = this;
      const successHandler = (res) => {
        console.log("Success: ", res.data);
      };
      const failureHandler = (res) => {
        console.log("Failure: ", res);
      };
      let formData = {};
      // {
      //   "district": 0,
      //   "school": 0,
      //   "teacher": 0,
      //   elseif "student": 0
      // }
      formData["email_template_id"] = this.templateId;
      formData["shared_by"] = "student";
      formData["platform"] = platform;
      formData["campaign"] = this.getCampaignDetail.campaign_detail.id;
      formData["student"] = this.$route.query.student;
      Axios.request_POST(
        API_PUBLIC_SHARE_POST_DETAILS,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
  },
  mounted() {},
};
</script>

<style scoped>
.sectionTitle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 140%;
  color: #2c1963;
}
.shareTextBox {
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 8px;
}
.shareTextBox span {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  /*font-size: 17px;*/
  /*line-height: 140%;*/
  color: #4d4d4d;
}
.btn-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0px;
  text-transform: capitalize;
}
.bottomMsgText {
  width: 80%;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  color: #757575;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sectionTitle {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    color: #2c1963;
    text-align: center;
  }
  .shareTextBox span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #4d4d4d;
  }
  .btn-text {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0px;
    text-transform: capitalize;
  }
  .bottomMsgText {
    width: 80%;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    color: #757575;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
</style>
